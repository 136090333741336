<template>
	<div class="video-heading-image">
		<div style="padding: 56.25% 0 0; position: relative">
			<iframe
				:src="`${video}?controls=0&wmode=opaque&showinfo=0&rel=0&autoplay=1&playsinline=1&loop=1&mute=1&playlist=${getVideoID()}`"
				frameborder="0"
				allow="autoplay; fullscreen; picture-in-picture"
				allowfullscreen
				style="position: absolute; top: -75px; left: 0; width: 100%; height: 100%; z-index: 2"
			/>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	video: { type: String, default: '' },
	type: { type: String, default: '' },
});

const getVideoID = () => {
	return props.video.split('/').at(-1);
};
</script>

<style lang="scss" scoped>
.video-heading-image {
	position: relative;
	overflow: hidden;
	background-color: #fdf9f4;
	max-height: 100vh;
	pointer-events: none;

	&::after {
		content: '';
		height: calc(100% - 75px);
		width: 100%;
		top: 0;
		position: absolute;
		left: 0;
		background-color: rgb(0 0 0 / 20%);
		z-index: 2;
	}
}

.subpage {
	.video-heading-image {
		max-height: calc((70vw / 16) * 9);

		&::after {
			height: 100%;
		}
	}
}

@media (max-width: 680px) {
	.video-heading-image {
		min-height: 640px;

		iframe {
			width: 360% !important;
			height: 130% !important;
			margin-left: -130% !important;
			min-height: 640px !important;
		}
	}

	.subpage {
		.video-heading-image {
			height: 460px;
			min-height: 460px;
		}
	}
}

@media (max-width: 520px) {
	.video-heading-image {
		min-height: 600px;

		iframe {
			width: 300% !important;
			height: 120% !important;
			margin-left: -100% !important;
			min-height: 600px !important;
		}
	}
}
</style>
